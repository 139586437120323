<template>
  <Transition name="fade">
    <div v-show="show">
      <div :class="props.fullscreen ? 'bg-neutral-100 w-screen h-screen top-0 left-0  fixed z-[10000]' : 'w-full h-full'">
        <div class="flex  justify-center w-full h-full" :class="props.fullscreen ? 'flex-col' : ' flex-row'">
          <div class="text-center">
            <i class="pi pi-spin pi-spinner text-primary-dark" style="font-size: 2rem"></i>
          </div>
          <p v-if="props.message.length" class="text-center text-primary-dark text-xl" :class="props.fullscreen ? '' : 'ml-4 my-auto'">{{ props.message }}</p>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">

const props = defineProps({
  message: {
    type: String,
    default: ''
  },
  fullscreen: {
    type: Boolean,
    default: true
  },
  show: {
    type: Boolean,
    default: false
  }
})

</script>